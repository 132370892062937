import tw, { styled } from 'twin.macro'

const Button = styled.a(({ variant }) => [
  // The common button styles added with the tw import
  tw`flex justify-center items-center rounded-full`,
  tw`w-[140px] h-10 border-2`,

  // Use props to conditionally style your components
  variant === 'white' && tw`border-white text-white hover:bg-white hover:text-black`,
  variant === 'black' && tw`border-black text-black hover:bg-black hover:text-white`,
  variant === 'whiteB' && tw`border-white text-black bg-white`,
  variant === 'blackB' && tw`border-black text-white bg-black`,

])

export default Button

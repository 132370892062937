import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"

import { ReactComponent as Weixin } from '../images/weixin.svg'
import { ReactComponent as Weibo } from "../images/weibo.svg"
import { ReactComponent as Linkedin } from "../images/linkedin.svg"
import { ReactComponent as Bilibili } from "../images/bilibili.svg"
import { ReactComponent as Toktik } from "../images/toktik.svg"



export default function MediaDetail({pageContext: { node,list },location}) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const currIndex = list.node.media_details.findIndex((n)=>{
        return n.url == node.url
    })

    const pageQuery = useStaticQuery(graphql`
        query {
            allStrapiGlobal {
                edges {
                    node {
                        weixin,
                        weibo,
                        bilibili,
                        toktik,
                        linkedin
                    }
                }
            }
        }`)
    const nodeSocial = pageQuery.allStrapiGlobal.edges[0].node
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[200px] pb-12 sm:pt-24">
                <ul className="flex items-center flex-wrap text-gray">
                    <li><a className="hover:text-black" href="/media">媒体中心</a>&nbsp;&gt;&nbsp;</li>
                    <li><a className="hover:text-black" href={node.media_category.url}>{node.media_category.title}</a>&nbsp;&gt;&nbsp;</li>
                    <li><a className="text-black hover:text-black" href={node.url}>{node.title}</a></li>
                </ul>
            </div>
            <article className="pb-[170px] case w-full overflow-hidden">
                <div className="container mx-auto">
                    <div className="flex items-center justify-end">
                        <div className="w-[944px]">
                            <div className="text-sm">{node.date}</div>
                            <h1>{node.title}</h1>
                            <ul className="flex items-center -mt-28 sm:-mt-14">
                                <li className="mr-3 hover:opacity-80"><a className="text-black" href={nodeSocial.weixin}><Weixin width={26}/></a></li>
                                <li className="mr-3 hover:opacity-80"><a className="text-black" href={nodeSocial.weibo}><Weibo width={26}/></a></li>
                                <li className="mr-3 hover:opacity-80"><a className="text-black" href={nodeSocial.linkedin}><Linkedin width={26}/></a></li>
                                <li className="mr-3 hover:opacity-80"><a className="text-black" href={nodeSocial.bilibili}><Bilibili width={24}/></a></li>
                                <li className="mr-3 hover:opacity-80"><a className="text-black" href={nodeSocial.toktik}><Toktik width={18}/></a></li>
                            </ul>
                            <p className="mt-[100px] sm:mt-20">{node.description}</p>
                        </div>
                    </div>
                </div>
                <figure className="max-w-[1640px] w-full mx-auto my-10 overflow-hidden"><img src={`https://admin.cnsotto.com${node.seo.shareImage.url}`} alt="media picture"/></figure>
                <div className="container mx-auto">
                    <article dangerouslySetInnerHTML={{__html: node.article}}/>
                </div>
            </article>
            <div className="container mx-auto pt-[30px] pb-[140px]">
                    <h3 className="text-[40px] mb-10 leading-snug">相关新闻推荐</h3>
                    
                    <div className="grid grid-cols-3 gap-x-[45px] xl:grid-cols-2 sm:grid-cols-1 xl:gap-y-20">
                    {
                        list.node.media_details.map((n,i,a)=>{
                            if((i > currIndex && i < currIndex+4) || ((currIndex > a.length-4) && i < 3-(a.length-currIndex-1))){
                                return (
                                <div key={i} className="bg-white">
                                    <figure className="h-[330px] xl:h-auto overflow-hidden"><img className="w-full h-full object-cover bg-light transform transition-all duration-1000 ease-linear hover:scale-110" src={`https://admin.cnsotto.com${n.seo.shareImage.url}`} alt="picture"/></figure>
                                    <p className="py-2.5 text-sm">{n.date}</p>
                                    <h2 onClick={()=>window.location.href=n.url} className="text-3xl mb-10 leading-snug h-[82px] truncate-2-lines cursor-pointer sm:h-auto">{n.title}</h2>
                                    <Button href={n.url} variant="black">阅读更多</Button>
                                </div>)
                            }
                            
                        })
                    }
                    </div>

                </div>
        </Layout>
    )
}

